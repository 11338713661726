import React, { useContext } from "react"
import Background from "./Background"
import styled from "styled-components"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyContext } from "../context/context"
import TEXT_MESSAGE from "../constants/text-messages"
//penn-love-750x500.png
//philis1-3-2.jpg
const query = graphql`
  {
    background: file(relativePath: { eq: "boston.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Hero = ({ projects }) => {
  const {
    background: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  const { language } = useContext(GatsbyContext)
  return (
    <Wrapper>
      <Background image={fluid}>
        <article>
          <div className="header-container">
            <h1>
              {language === "ko"
                ? TEXT_MESSAGE.hero_h1.ko
                : TEXT_MESSAGE.hero_h1.en}
            </h1>
            <h2>
              {language === "ko"
                ? TEXT_MESSAGE.hero_h2.ko
                : TEXT_MESSAGE.hero_h2.en}
            </h2>
          </div>
          <div className="cta-container">
            <Link to="/projects">
              {" "}
              {language === "ko"
                ? TEXT_MESSAGE.hero_link_project.ko
                : TEXT_MESSAGE.hero_link_project.en}
            </Link>
          </div>
        </article>
      </Background>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  article {
    width: 85vw;
    color: var(--clr-white);
    display: grid;
    place-items: center;
    padding-top: 6rem;
  }
  h1 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    margin: 1rem 0 3rem 0;
    text-transform: none;
  }
  h2 {
    font-weight: 400;
    line-height: 1.25;
    font-size: 1.25rem;
    text-transform: none;
  }
  .header-container {
    display: flex;
    flex-direction: column;
  }
  .cta-container {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  a {
    display: inline-block;
    background: rgba(255, 255, 255, 0.9);
    outline: var(--clr-white) solid 2px;
    outline-offset: 5px;
    padding: 0.25rem 1rem;
    text-transform: capitalize;
    letter-spacing: 2px;
    color: var(--clr-black);
    font-size: 1rem;
    cursor: pointer;
    transition: var(--transition);
  }
  a:hover {
    background: #378ccf;
    color: var(--clr-white);
    outline: #378ccf solid 2px;
  }
  @media (min-width: 768px) {
    /* padding: 0 1rem; */
    a {
      font-size: 1.25rem;
      padding: 0.5rem 1rem;
    }
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
`

export default Hero
