import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// const query = graphql`
//   {
//     file(relativePath: { eq: "hangang-panorama-7424px.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

const Background = ({ children, image }) => {
  //   const {
  //     file: {
  //       childImageSharp: { fluid },
  //     },
  //   } = useStaticQuery(query)

  return (
    <Wrapper>
      <BackgroundImage
        Tag="div"
        fluid={image}
        className="bcg"
        preserveStackingContext={true}
      >
        {children}
      </BackgroundImage>
    </Wrapper>
  )
}

const fadeIn = keyframes`
      from{
         background-color:rgb(0,0,0,0.9);
      }
      to{
        background-color:rgba(0,0,0,0.55);
      }
      `

const Wrapper = styled.section`
  .bcg {
    /* MUST!!!!!! */
    min-height: 100vh;
    margin-top: -5rem;
    display: grid;
    place-items: center;
    animation: ${fadeIn} 2s ease-in-out 1 forwards;
  }
  .bcg::before {
    opacity: 1;
  }
  /* https://css-tricks.com/infinite-all-css-scrolling-slideshow/ */
  .slideshow {
    position: relative;
    overflow: hidden;
  }
  .images {
    background: url(slideshow.jpg);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 300%;
    animation: slideshow 10s linear infinite;
  }
  @keyframes slideshow {
    0% {
      left: 0;
    }
    100% {
      left: -200%;
    }
  }
`
export default Background
