import React, { useContext } from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyContext } from "../context/context"
import TEXT_MESSAGE from "../constants/text-messages"
//penn-love-750x500.png
//philis1-3-2.jpg
const query = graphql`
  {
    dataIcon: file(relativePath: { eq: "data-analysis.svg" }) {
      extension
      publicURL
    }
    webIcon: file(relativePath: { eq: "web-design.svg" }) {
      extension
      publicURL
    }
    surveyIcon: file(relativePath: { eq: "survey.svg" }) {
      extension
      publicURL
    }
    whartonLogo: file(relativePath: { eq: "wharton.svg" }) {
      extension
      publicURL
    }
    brainventuresLogo: file(relativePath: { eq: "brainventures.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
// ...GatsbyImageSharpFluid
const ServiceInfo = () => {
  const {
    dataIcon: { extension: extension1, publicURL: publicURL1 },
    webIcon: { extension: extension2, publicURL: publicURL2 },
    surveyIcon: { extension: extension3, publicURL: publicURL3 },
    whartonLogo: { extension: extension4, publicURL: publicURL4 },
    brainventuresLogo: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  const { language } = useContext(GatsbyContext)
  return (
    <Wrapper className="">
      <div className="info-section">
        <div className="info-title">What We Do</div>
        <div className="service-container">
          <div className="service">
            <div className="service-icon">
              {extension1 === "svg" && (
                <img src={publicURL1} className="service-icon" />
              )}
            </div>
            <div className="service-title">
              {" "}
              {language === "ko"
                ? TEXT_MESSAGE.service_infos[0].title.ko
                : TEXT_MESSAGE.service_infos[0].title.en}
            </div>
            <div className="service-info">
              {language === "ko"
                ? TEXT_MESSAGE.service_infos[0].info.ko
                : TEXT_MESSAGE.service_infos[0].info.en}
            </div>
          </div>
          <div className="service">
            <div className="service-icon">
              {extension2 === "svg" && (
                <img src={publicURL2} className="service-icon" />
              )}
            </div>
            <div className="service-title">
              {" "}
              {language === "ko"
                ? TEXT_MESSAGE.service_infos[1].title.ko
                : TEXT_MESSAGE.service_infos[1].title.en}
            </div>
            <div className="service-info">
              {language === "ko"
                ? TEXT_MESSAGE.service_infos[1].info.ko
                : TEXT_MESSAGE.service_infos[1].info.en}
            </div>
          </div>
          <div className="service">
            <div className="service-icon">
              {extension3 === "svg" && (
                <img src={publicURL3} className="service-icon" />
              )}
            </div>
            <div className="service-title">
              {" "}
              {language === "ko"
                ? TEXT_MESSAGE.service_infos[2].title.ko
                : TEXT_MESSAGE.service_infos[2].title.en}
            </div>
            <div className="service-info">
              {language === "ko"
                ? TEXT_MESSAGE.service_infos[2].info.ko
                : TEXT_MESSAGE.service_infos[2].info.en}
            </div>
          </div>
        </div>
        <div className="info-title">Our Clients</div>
        <div className="client-container">
          {extension4 === "svg" && (
            <img src={publicURL4} className="client-icon" />
          )}
          <Image fluid={fluid} className="client-icon" />
        </div>
      </div>
      <div className="contact-container">
        <div className="contact-info">
          {" "}
          {language === "ko"
            ? TEXT_MESSAGE.service_contact.ko
            : TEXT_MESSAGE.service_contact.en}
          info@kimandhwang.com
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-grey-10);
  color: var(--clr-black);
  font-family: var(--ff-primary);
  .info-section {
  }
  .info-title {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    font-family: var(--ff-primary);
    font-size: 2rem;
    font-weight: 700;
  }
  .service-container {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-bottom: 2rem;
  }
  .service {
    margin-bottom: 2.5rem;
  }
  .service-icon {
    max-width: 75px;
    height: 75px;
    margin: 0 auto;
  }
  .service-title {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 700;
  }
  .service-info {
    width: 250px;
    margin-top: 0.25rem;
  }
  .client-container {
    display: grid;
    grid-template-columns: 100px 100px;
    column-gap: 1rem;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  .client-icon {
    width: 100px;
  }
  .contact-container {
    padding: 3rem 0;
    text-align: center;
  }
  .contact-info {
    display: inline-block;
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    background: #378ccf;
    color: var(--clr-white);
  }
  @media (min-width: 768px) {
    .info-title {
      font-size: 2.5rem;
    }
    .contact-info {
      font-size: 1.25rem;
    }
    .service-icon {
      max-width: 200px;
      height: 200px;
    }
    .service-title {
      font-size: 1.5rem;
    }
    .service-info {
      width: 300px;
    }
    .client-container {
      grid-template-columns: 200px 200px;
      column-gap: 3rem;
    }
    .client-icon {
      width: 200px;
    }
  }
  @media (min-width: 992px) {
    .service-container {
      grid-template-columns: repeat(3, 1fr);
    }
    .service {
      margin-bottom: 0;
    }
  }
`
export default ServiceInfo
